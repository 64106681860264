.block.accordion {
  border: 0;

  .accordion-item {
    .accordion-header p,
    .accordion-header button,
    .accordion-header .title {
      color: $primary;
    }

    .accordion-header .title {
      padding: 0;
      margin-top: 1rem;
    }
  }

  .section.full-width
    .card
    .card-body
    .accordion-item
    .subblock-edit
    .accordion-header
    .title {
    color: $primary;
  }
}
