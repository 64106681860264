.block.iconBlocks .card {
  a.read-more {
    color: $site-link-color;
    text-decoration: none;

    &:hover {
      color: $link-hover-color;
      text-decoration: underline;
    }
  }
}
