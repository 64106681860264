.breadcrumb-container {
  .breadcrumb {
    li.breadcrumb-item {
      a {
        color: $primary;
        font-weight: 500;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      .separator {
        margin-left: 0;
        font-weight: 500;
      }
    }
  }
}
