.it-header-slim-wrapper {
  background: $site-header-slim-bg-color;
  .tertiary-menu {
    .external-link {
      fill: $tertiary-text !important;
    }
  }
  .parent-site-menu {
    li.nav-item {
      border-right: 1px solid $tertiary-text;

      a.nav-link {
        color: $tertiary-text;
      }
    }
  }
}
