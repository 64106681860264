//site
$primary: #093966;
$primary-h: 209 !default;
$primary-s: 91 !default;
$primary-b: 40 !default;

$tertiary: $primary;

$primary-light: #f9fafc;
$primary-dark: #002033;

// header and footer
$header-center-bg-color: $primary;
$site-header-slim-bg-color: $primary-dark;
$footer-bg-color: $primary-dark;

//text
$secondary-text: #f9fafc;

$site-link-color: tint-color($primary-dark, 3);
$link-hover-color: $primary-dark;

$argument-icon-color: $primary;
$argument-icon-bg: $primary-light;
