.argumentInEvidence {
  h2 {
    color: $body-color !important;
  }
  .read-more {
    margin-right: 1.2rem;

    &:hover {
      color: $primary;

      .icon {
        fill: $primary;
      }
    }
  }

  .link-button {
    a.btn.btn-primary {
      color: $tertiary-text;
    }
  }
}
