.complete-block-links-template {
  .card.card-bg {
    background-color: $primary;

    a,
    h3,
    p {
      color: $tertiary-text !important;
    }

    h3 {
      margin-bottom: 0 !important;
    }
  }
}
