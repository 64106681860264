.block.listing .card-with-image-template {
  .listing-item .card-title a,
  .category-top {
    color: $primary;

    .icon {
      color: $primary;
      fill: $primary;
    }
  }
}
